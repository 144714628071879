import type { TagType, $Product, ValidProductType, FormFactorType } from '~/app/common/types'

export interface Form {
  renderField: (name: string, props?: object) => JSX.Element
}

export interface FormWithAllergens extends Form {
  formState: { productDietaryTags: { value: TagType[] }; productAllergens: { value: TagType[] } }
  allergens: TagType[]
}

export interface FormWithAllergensDietaryTags extends FormWithAllergens {
  dietaryTags: TagType[]
}

export interface DetailedForm {
  match: {
    params: {
      productId: string
    }
  }
  isNew: boolean
  hasChanges: () => boolean
  formData: $Product
  addOn: boolean
  allergens: TagType[]
  dietaryTags: TagType[]
  formFactors: FormFactorType[]
  onConfirm: (data: any) => void
  type: ValidProductType
}

export enum FieldNames {
  color = 'color',
  description = 'description',
  formFactors = 'formFactors',
  heatingInstructionId = 'heatingInstructionId',
  isFreeShipping = 'isFreeShipping',
  heightInches = 'heightInches',
  ingredients = 'ingredients',
  ingredientsText = 'ingredientsText',
  weightTarePounds = 'weightTarePounds',
  keyHighlights = 'keyHighlights',
  labelId = 'labelId',
  lengthInches = 'lengthInches',
  longName = 'longName',
  name = 'name',
  nutritionHighlight = 'nutritionHighlight',
  nutritionFull = 'nutritionFull',
  nutritionFullAltText = 'nutritionFullAltText',
  packetCount = 'packetCount',
  price = 'price',
  priceCents = 'priceCents',
  productDietaryTags = 'productDietaryTags',
  productAllergens = 'productAllergens',
  recommendedAge = 'recommendedAge',
  seoKeywords = 'seoKeywords',
  seoTitle = 'seoTitle',
  sequence = 'sequence',
  shadow = 'shadow',
  shadowAltText = 'shadowAltText',
  simple = 'simple',
  simpleAltText = 'simpleAltText',
  sku = 'sku',
  sleeve = 'sleeve',
  sleeveAltText = 'sleeveAltText',
  spoon = 'spoon',
  stage = 'stage',
  symbols = 'symbols',
  thumbnail = 'thumbnail',
  thumbnailAltText = 'thumbnailAltText',
  top = 'top',
  topAltText = 'topAltText',
  type = 'type',
  weightMaxPounds = 'weightMaxPounds',
  weightPounds = 'weightPounds',
  widthInches = 'widthInches',
  code = 'code',
  expiresAt = 'expiresAt',
  discount = 'discount',
  percentage = 'percentage',
  overallMaxUses = 'overallMaxUses',
  checkoutOnly = 'checkoutOnly',
  discountShipping = 'discountShipping',
  productTypeRestrictions = 'productTypeRestrictions',
  checkoutMessage = 'checkoutMessage',
  recurring = 'recurring',
  maxDiscountCents = 'maxDiscountCents',
  title = 'title',
  startDate = 'startDate',
  endDate = 'endDate',
  textColor = 'textColor',
  backgroundColor = 'backgroundColor',
  couponCode = 'couponCode',
  redirectURL = 'redirectURL',
  isForNonAuthUsersOnly = 'isForNonAuthUsersOnly',
  scope = 'scope',
  minProducts = 'minProducts',
  footerTermsText = 'footerTermsText',
  autoApplyCouponOnClick = 'autoApplyCouponOnClick',
  effectiveUntil = 'effectiveUntil',
  primaryIssue = 'primaryIssue',
  secondaryIssue = 'secondaryIssue',
  note = 'note',
  photosProvided = 'photosProvided',
  resend = 'resend',
  extraProductIds = 'extraProductIds',
  freeGift = 'freeGift',
  intercomLink = 'intercomLink',
  targetingTypes = 'targetingTypes',
  isNew = 'isNew',
  targetRoutes = 'targetRoutes',
  isCustomRefund = 'isCustomRefund',
  customRefundAmount = 'customRefundAmount',
  customRefundReason = 'customRefundReason',
}

export type FieldName = keyof typeof FieldNames

export interface FormField {
  dirty: boolean
  errorMsg: string
  label: string
  name: string
  onChange: (...args: any[]) => void
  options?: any[]
  pristine: boolean
  type: any
  valid: boolean
  value: any
  validators?: ((...args: any[]) => any)[]
  emptyLabel?: string
  imageType?: string
  hasEmptyOption?: boolean
  timesSubmitted?: number
}

export type FormState = Record<string, FormField>
