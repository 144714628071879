import { connect } from 'react-redux'
import queryString from 'query-string'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import SmallSelect from '~/app/components/InputTypes/SmallSelect'
import { searchUser, fetchUsers, updatePage } from '~/app/store/users/actions'
import { selectSearchTerm, selectPage } from '~/app/store/users/selectors'
import { selectCurrentFilter } from '~/app/store/filters/selectors'
import { USERS_PER_PAGE } from '~/app/store/users/constants'
import SearchIcon from '~/app/assets/svg/SearchIcon'
import { Wrap, SearchBarInput, SelectContainer } from './styled'

const SEARCH_FILTER = {
  EMAIL: 'email',
  ORDER_ID: 'orderId',
  PHONE: 'phone',
  USER_NAME: 'userName',
}

const searchOptions = [
  {
    label: 'Email',
    value: SEARCH_FILTER.EMAIL,
  },
  {
    label: 'Customer Name',
    value: SEARCH_FILTER.USER_NAME,
  },
  {
    label: 'Phone Number',
    value: SEARCH_FILTER.PHONE,
  },
  {
    label: 'Order Id',
    value: SEARCH_FILTER.ORDER_ID,
  },
]

interface Props {
  onSearchTermChange: () => void
  placeholder: string
  searchTerm: string
  searchFilter: string
  setSearchFilter: () => void
}

function SearchBar(props: Props) {
  return (
    <Wrap>
      <SearchIcon />

      <SelectContainer>
        <SmallSelect
          // TODO: LS-3279 save this in store as `users.searchFilter`
          // @ts-ignore
          id="usersSearchFilter"
          options={searchOptions}
          value={props.searchFilter}
          onChange={props.setSearchFilter}
        />
      </SelectContainer>

      <SearchBarInput
        value={props.searchTerm}
        placeholder={props.placeholder}
        onChange={props.onSearchTermChange}
      />
    </Wrap>
  )
}

function mapStateToProps(state: any) {
  return {
    activePage: selectPage(state),
    filter: selectCurrentFilter(state),
    searchTerm: selectSearchTerm(state),
  }
}

const mapDispatchToProps = {
  fetchUsers,
  searchUser,
  updatePage,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('searchFilter', 'setSearchFilter', SEARCH_FILTER.EMAIL),

  // @ts-expect-error
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps: any) {
      const { fetchUsers, filter, history, searchTerm, updatePage } = this.props
      const { searchFilter: nextSearchFilter, searchTerm: nextSearchTerm } = nextProps

      if (
        searchTerm !== nextSearchTerm &&
        (nextSearchTerm.length > 2 || nextSearchTerm.length === 0)
      ) {
        history.push('/users/list')
        updateQueryStringSearch(history, 1, nextSearchTerm)
        fetchUsers({
          filter,
          limit: USERS_PER_PAGE,
          offset: 0,
          searchFilter: nextSearchFilter,
          searchTerm: nextSearchTerm,
        })
        updatePage()
      }
    },
  }),

  withHandlers({
    onSearchTermChange: (props: any) => (event: any) => {
      const { activePage, history, searchUser } = props
      const searchTerm = stripSpaces(event.target.value)
      updateQueryStringSearch(history, activePage, searchTerm)
      searchUser(searchTerm)
    },
  }),
  // @ts-ignore
)(SearchBar)

function stripSpaces(s: string): string {
  return s.replaceAll(/\s/g, '')
}

function updateQueryStringSearch(
  history: Record<string, any>,
  activePage: number,
  searchTerm: string,
) {
  history.replace({
    search: queryString.stringify({
      'user-page': activePage,
      'user-search': searchTerm || undefined,
    }),
  })
}
